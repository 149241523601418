import * as conf from "../conf.yml";
import store from "@/store";

function showConfirmBox() {
  const answer = window.confirm("Changes you made may not be saved.");
  if (!answer) return false;
  store.commit('SET_ROUTE_LEAVE_ALERT', false);
  return true;
}

export function confirmBox(isAlertShow, next) {
  const showAlert = isAlertShow && conf.default.routeLeaveAlert;
  let leaveGranted = true;
  if (showAlert) {
    leaveGranted = showConfirmBox();
  }

  if (arguments.length === 2 && leaveGranted){
    return next();
  } else if (arguments.length === 1 && leaveGranted) {
    return true;
  } else {
    return false;
  }
}

export function setLeaveAlert(ignore, value){
  if(ignore){
    return false;
  }

  if(arguments.length === 2){
    store.commit('SET_ROUTE_LEAVE_ALERT', value);
  } else {
    store.commit('SET_ROUTE_LEAVE_ALERT', true);
  }
  
  return ignore;
}

export function resetLeaveAlert(){
  store.commit('SET_ROUTE_LEAVE_ALERT', false);
  return;
}