<template>
  <v-card class="images-container-card">
    <v-row>
      <v-col cols="12" md="12">
        <div id="fileUpload" class="image-container">
          <v-row>
            <v-col cols="6" style="padding:10px 0px;">
              <v-card-text class="text-left pb-0">
                <p class="title_style">
                  {{imageCategory}}
                </p>
              </v-card-text>
            </v-col>
            <v-col v-if="showAddBtn" cols="6" class="images-add-btn">
              <md-button class="save-btn" @click="editsix = true;">
                Add
              </md-button>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              class="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-2"
              v-for="file in companyImages"
              :key="file.id"
            >
              <div>
                <md-card class="my-2 image-card">
                  <md-card-media>
                    <embed
                      class="centered-and-cropped"
                      v-if="isSupportedFileType(file.upload_name)"
                      :src="
                          `${baseUrl}/api/user/file_show/${file.upload_name}`
                      "
                      :type="file.mime_type"
                    />
                    <div v-else>
                      <div class="font-weight-bold not-supported-file subtitle_data">
                        {{ file.upload_name }}
                      </div>
                      <div class="subtitle_data">Please download this file to view.</div>
                      </div>
                      <div v-if="isSupportedFileType(file.upload_name) && showOptionBtn" class="more-vert" @click="showOption(file.id)"><v-icon class="more-vert-icon">more_vert</v-icon></div>
                      <div v-if="isSupportedFileType(file.upload_name) && actionId == file.id" class="actions-container">
                        <!-- <div class="actions-list">Download</div> -->
                        <div class="actions-list" @click="deleteImage(file.upload_name)">Delete</div>
                    </div>
                  </md-card-media>
                  <div v-if="!isSupportedFileType(file.upload_name) && showOptionBtn" class="more-vert" @click="showOption(file.id)"><v-icon class="more-vert-icon">more_vert</v-icon></div>
                  <div v-if="!isSupportedFileType(file.upload_name) && actionId == file.id" class="actions-container">
                    <!-- <div class="actions-list">Download</div> -->
                    <div class="actions-list" @click="deleteImage(file.upload_name)">Delete</div>
                  </div>
                </md-card>
                <div class="image-description">
                  <p class="subtitle_data font-weight-bold text-left">
                    {{ file.caption }}
                  </p>
                  <p class="subtitle_data text-left">
                    {{ file.description }}
                  </p>
                  <p class="subtitle_data text-left">
                    {{ $moment(file.created_at).format("HH:mm, D MMM Y") }}
                  </p>
                </div>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-col>
      <v-dialog v-model="editsix" persistent content-class="v-dialog-data">
          <v-card>
          <v-card-title>
              <p class="title_style">
                Upload Image
              </p>
              <v-divider justify="center" class="dialog_divider_style"/>
          </v-card-title>
          <v-form ref="uploadImage">
              <v-container class="py-0">
              <v-row class="add-image-container">
                  <v-col cols="12" md="12">
                  </v-col>
                  <v-col md="12">
                    <md-field>
                      <input
                      type="file"
                      ref="images"
                      :accept="acceptedFileTypes"
                      multiple
                    />
                    </md-field>
                  </v-col>
                  <v-col md="12">
                    <v-text-field
                      label="Caption"
                      v-model="form.imageCaption"
                      outlined
                      dense
                      :rules="rules.captionRules"
                      color="#473068"
                      class="mb-2"
                    ></v-text-field>
                    <v-text-field
                      label="Category"
                      v-model="imageCategory"
                      outlined
                      dense
                      color="#473068"
                      class="mb-2"
                      disabled
                    ></v-text-field>
                    <div v-if="imageCategory == 'Products'">
                      <v-textarea
                        label="Description"
                        v-model="form.imageDescription"
                        :rules="rules.textAreaRules"
                        outlined
                        dense
                        color="#473068"
                        class="mb-2"
                      ></v-textarea>
                    </div>
                  </v-col>
              </v-row>
              </v-container>
          </v-form>
          <v-card-actions class="btn-container">
              <v-spacer></v-spacer>
              <md-button
              class="close-btn"
              @click="editsix = false"
              >Close
              </md-button
              >
              <md-button class="save-btn" @click="addImage"
              >Upload
              </md-button
              >
          </v-card-actions>
          </v-card>
      </v-dialog>
    </v-row>
  </v-card>
</template>

<script>
import restAdapter from "../../../restAdapter";
import utils from "../../../utils";
import notification from "../../../notification";
import { confirmBox, resetLeaveAlert, setLeaveAlert } from "../../../utils/confirmBox";
import * as conf from "../../../conf.yml";

export default {
  name: "SupplierProfileImages",
  props: {
    companyImages: {
      type: Array,
      default: () => [],
    },
    baseUrl:{
      type: String,
      default: conf.default.server.host,
    },
    imageCategory:{
      type: String,
      default: "",
    },
    showAddBtn:{
      type: Boolean,
      default: true
    },
    showOptionBtn:{
      type: Boolean,
      default: true
    },
  },
  data: () => ({
    actionId: null,
    editsix: false,
    form: {
        imageCaption: null,
        imageDescription: null,
      },
    acceptedFileTypes: utils.acceptedFileTypes,
    rules: {
        captionRules: [
          (value) => !!value || "Caption is required.",
          (value) =>!value ||
            (value && value.length <= 255) ||
            "Must be no more than 255 characters",
          ],
        textAreaRules: [
          (value) =>
            !value ||
            (value && value.length <= 255) ||
            "Must be no more than 255 characters",
          ],
      },
  }),

  methods: {

    isSupportedFileType(filename) {
      const extension =
        filename.match(/\.[0-9a-z]+$/i) && filename.match(/\.[0-9a-z]+$/i)[0];
      const supportedFileTypes = this.acceptedFileTypes.split(",");
      return supportedFileTypes.includes(extension.toLowerCase());
    },

    showOption(id){
      this.actionId = this.actionId ? null : id;
    },

    addImage() {
      if (!this.$refs.uploadImage.validate()) {
        return;
      } else if (this.$refs.images.files.length < 1) {
        return;
      }
      const formData = new FormData();
      let filetype = 0;
      for (let i = 0; i < this.$refs.images.files.length; i++)
        formData.append("image[]", this.$refs.images.files[i]);
      switch (this.imageCategory) {
        case "Company Gallery":
          filetype = 1;
          break;
        case "Products":
          filetype = 2;
          break;
        case "Certifications":
          filetype = 3;
          break;
      }
      formData.append("file_type", filetype);
      formData.append("caption", this.form.imageCaption);
      formData.append("description", this.form.imageDescription);
      formData.append("category_name", 0);
      restAdapter
        .post("/api/update_profile/images", formData)
          .then(() => {
            notification.success("Image Uploaded");
            this.$emit("get-users", 1);
            this.form.imageCaption = null;
            this.imageCategory = "Company Gallery";
            this.form.imageDescription = null;
            this.$refs.images.value = null;
            this.$refs.uploadImage.reset();
            this.ignoreFormEdit = true;
            this.routeLeaveAlertForm = false;
            if(!this.routeLeaveAlertPassword){
              resetLeaveAlert();
            }
          }
        )
        .catch((error) => {
          if (
            error.response &&
            error.response.status == 400 &&
            error.response.data &&
            error.response.data.error
          ) {
            notification.errors(error.response.data.error);
          } else {
            notification.error("Invalid file type");
          }
        });
    },

    deleteImage(id) {
      if (confirm("Delete this file?")) {
        restAdapter.delete("/api/user/file_delete/" + id).then(() => {
          this.$emit("get-users", 1);
        });
      }
    },
  }
};

</script>

<style lang="scss" scoped>

.images-container-card{
  background: #FFFFFF;
  box-shadow: 0px 3px 6px rgba(71, 48, 104, 0.06) !important;
  border-radius: 7px !important;
  padding-bottom: 30px;
  margin: 8px 0px 30px 0px;
}

.image-container{
  margin: 8px 50px 0px 50px;
}

.title_style{
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: #473068;
  text-decoration-line: underline;
  text-align: left;
}

.images-add-btn{
  display: flex;
  justify-content: flex-end;
  -webkit-justify-content: flex-end;
  align-items: center;
  padding-right: 10px;
}

.save-btn{
  color: #FFFFFF !important;
  background: #473068;
  border-radius: 3px;
  height: 40px;
}

.image-card{
  border: 0.5px solid #442D64;
  filter: drop-shadow(0px 4px 4px rgba(72, 49, 105, 0.15));
  border-radius: 4px;
  height: 201px;
}

.centered-and-cropped{
  object-fit: cover;
  height: 100%;
  width: 100%;
  border-radius:4px;
  height: 198.5px;
}

.not-supported-file{
  margin-top: 125px;
  margin-bottom: 10px;
}

.subtitle_data{
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #473068;
}

.more-vert{
  background: $paginateText;
  width: 30px;
  height: 30px;
  float: right;
  border-radius: 50%;
  cursor: pointer;
  align-items: center;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 5px;
  right: 5px;
}

.more-vert:hover{
  border: 0.5px solid $primaryDark;
  filter: drop-shadow(0px 4px 4px rgba(72, 49, 105, 0.15));
}

.more-vert-icon{
  font-size: 15px;
  vertical-align: unset;
}

.actions-container{
  position: absolute;
  right: 15px;
  top: 20px;
  width: 125px;
  height: auto;
  overflow: visible;
  z-index: 10;
  background: rgba(255, 255, 255, 0.85);
  box-shadow: 2.96703px 13.8462px 11.8681px rgba(0, 0, 0, 0.1);
  border-radius: 4.25385px;
  text-align: left;
}

.actions-list{
  overflow: visible;
  color: $infoMessage;
  cursor: pointer;
  font-size: 18px;
  padding-left: 10px;
}

.actions-list:hover {
  color: $tableBorder;
  background: rgba(255, 255, 255, 0.95);
}

.image-description{
  margin: 16px 0px 0px 1px;
}

.v-dialog-data{
  width: 70% !important;
}

.dialog_divider_style{
  border-color: #442D65 !important;
  margin: 20px 0px 50px 0px;
}

.add-image-container{
  margin: -50px 0px 10px 0px;
}

.md-field.md-theme-default:after {
  background-color: #473068;
}

.md-card{
    box-shadow: none;
}
</style>
